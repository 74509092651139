export default {
  id: '720fd64553',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=popup data-name=protection-popup id=page-print-protection-popup><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><!--\n                <div class="left">\n                    <a href="#" class="link back" id="page-attachment-back-popup">\n                        <i class="icon icon-back"></i>\n                        <span class="if-not-md">Back</span>\n                    </a>\n                </div>\n                --><div class=title>Защита</div><div class=right><a href=# class="link popup-close"><i class=material-icons>close</i></a></div></div></div><div class=page-content><div class=page-print-protection-popup-content></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};