export default {
  id: '5a7b933e99',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=popup data-name=protection-popup id=page-protection-product-popup><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><!--\n                <div class="left">\n                    <a href="#" class="link back" id="page-attachment-back-popup">\n                        <i class="icon icon-back"></i>\n                        <span class="if-not-md">Back</span>\n                    </a>\n                </div>\n                --><div class=title>Name</div><div class=right><a href=# class="link popup-close"><i class=material-icons>close</i></a></div></div></div><div class="page-content page-protection-product-popup-content"><div class=row><div class="col-50 page-protection-product-image">&nbsp;</div><div class="col-50 page-protection-product-packaging"><div class=data-table id=page-protection-product-packaging-table><table><tbody><tr><td>Штрихкод EAN13</td><td id=page-protection-product-packaging-table-ean13>-</td></tr><tr><td>Количество м² в коробке</td><td id=page-protection-product-packaging-table-korob_metr>-</td></tr><tr><td>Количество штук в коробке</td><td id=page-protection-product-packaging-table-korob_shtuk>-</td></tr><tr><td>Количество м² в штуке</td><td id=page-protection-product-packaging-table-shtuk_metr>-</td></tr><tr><td>Вес коробки (кг)</td><td id=page-protection-product-packaging-table-korob_ves>-</td></tr><tr><td>Коробок на паллете</td><td id=page-protection-product-packaging-table-pallet_korob>-</td></tr><tr><td>Вес паллеты (кг)</td><td id=page-protection-product-packaging-table-pallet_ves>-</td></tr></tbody></table></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};