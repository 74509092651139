export default {
  id: '050d3c5a2b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=settings-help><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>Помощь</div><!--\n                <div class="right">\n                    <a href="#" class="link icon-only" id="settings-logout">\n                        <i class="icon material-icons">exit_to_app</i>\n                    </a>\n                </div>\n                --></div></div><div class=page-content><div class=block-title style="margin-top: 16px;">По техническим вопросам</div><div class="block block-strong" style="margin-bottom: 0;"><p>e-mail: <a class="link external" href=mailto:semkov@uralgres.com>semkov@uralgres.com</a></p><p>Телефон: <a class="link external" href=tel:+79049440222>+7 (904) 944 02 22</a></p></div><div class=block-title style="margin-top: 16px;">По остальным вопросам к ООО "ЗКС"</div><div class="block block-strong" id=settings-help-manager1 style="margin-bottom: 0;"></div><div class=block-title style="margin-top: 16px;">По остальным вопросам к ООО "Фрилайт"</div><div class="block block-strong" id=settings-help-manager2></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};