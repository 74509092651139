export default {
  id: 'e7747a061d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=delivered-goods><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title id=delivered-goods-title>Поставляемая продукция</div><!--\n                <div class="right">\n                    <a href="#" class="link icon-only" id="settings-logout">\n                        <i class="icon material-icons">exit_to_app</i>\n                    </a>\n                </div>\n                --></div></div><div class="page-content data-table" id=delivered-goods-content><div class=block-title>Табличку грузим, грузим, никак не загрузим )))</div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};